import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const Language = () => {
  const { i18n } = useTranslation();
  const langOptions = [
    { label: 'English', value: 'en' },
    { label: 'Italian', value: 'it' }
  ];

  return (
    <div className="language">
      <label htmlFor="">Language</label>
      <Select
        className="input-select"
        classNamePrefix="input-select"
        options={langOptions}
        menuPlacement="top"
        defaultValue={langOptions.filter((option) => option.value === 'it')}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null
        }}
        onChange={(option) => i18n.changeLanguage(option.value)}
      />
    </div>
  );
};

export default Language;
