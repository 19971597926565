import { useState } from 'react';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');
import { useTranslation } from 'react-i18next';
import IconClose from '../../images/IconClose';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import toast from 'react-hot-toast';

export const AddModal = ({
  modalState,
  setModalState,
  childrenDetails,
  setChildrenDetails,
  setCheckboxState
}) => {
  const { isOpen } = modalState;
  const [kid, setKid] = useState({ firstName: '', lastName: '', gender: 'Male', dob: '' });
  const { t } = useTranslation();
  const genderOptions = [
    { label: t('customer.male'), value: 'Male' },
    { label: t('customer.female'), value: 'Female' }
  ];

  const saveHandler = () => {
    if (!kid.firstName) {
      toast.error(t('toast.enterFirstName'));
      return false;
    }
    if (!kid.lastName) {
      toast.error(t('toast.enterLastName'));
      return false;
    }
    if (!kid.gender) {
      toast.error(t('toast.selectGender'));
      return false;
    }
    if (!kid.dob) {
      toast.error(t('toast.enterDob'));
      return false;
    }
    setChildrenDetails((prevState) => [...prevState, { ...kid, id: childrenDetails.length + 1 }]);
    setModalState((previousState) => ({ ...previousState, isOpen: false }));
    setCheckboxState((prevState) => [...prevState, false]);
    setKid({ firstName: '', lastName: '', gender: 'Male', dob: '' });
  };

  const today = new Date();
  return (
    <ReactModal className="children-modal" isOpen={isOpen}>
      <header>
        <h1>{t('customer.editChildrenDetails')}</h1>
        <span
          className="icon-close"
          onClick={() => setModalState((previousState) => ({ ...previousState, isOpen: false }))}>
          <IconClose />
        </span>
      </header>
      <div className="row-container">
        <div className="adult-modal__first-name">
          <label>{t('customer.firstName')}</label>
          <input
            onChange={(e) => {
              setKid({ ...kid, firstName: e.target.value });
            }}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
        </div>
        <div className="adult-moda__last-name">
          <label>{t('customer.lastName')}</label>
          <input
            onChange={(e) => {
              setKid({ ...kid, lastName: e.target.value });
            }}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
        </div>
      </div>
      <div className="row-container">
        <div className="adult-modal__gender">
          <label>{t('customer.gender')}</label>
          <Select
            className="input-select"
            classNamePrefix="input-select"
            options={genderOptions}
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={(option) => {
              setKid((previousState) => ({ ...previousState, gender: option.value }));
            }}
          />
        </div>
        <div className="adult-modal__dob">
          <label>{t('customer.dob')}</label>
          <input
            type="date"
            max={today.toISOString().split('T')[0]}
            onChange={(e) => setKid({ ...kid, dob: new Date(e.target.value) })}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
        </div>
      </div>
      <div className="actions">
        <button className="actions-next-link" onClick={saveHandler}>
          {t('customer.save')}
        </button>
      </div>
    </ReactModal>
  );
};

AddModal.propTypes = {
  modalState: PropTypes.object,
  setModalState: PropTypes.func,
  childrenDetails: PropTypes.array,
  setChildrenDetails: PropTypes.func,
  checkboxState: PropTypes.array,
  setCheckboxState: PropTypes.func
};
