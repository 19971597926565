import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconClose from '../../images/IconClose';
import toast from 'react-hot-toast';

export const Modal = ({ modalState, setModalState, childrenDetails, setChildrenDetails }) => {
  const { index, isOpen } = modalState;
  const [kid, setKid] = useState({ firstName: '', lastName: '', gender: '', dob: '' });
  const { t } = useTranslation();
  const { id, firstName, lastName, gender } = childrenDetails[index] || {};
  const genderOptions = [
    { label: t('customer.male'), value: 'Male' },
    { label: t('customer.female'), value: 'Female' }
  ];

  useEffect(() => {
    isOpen ? setKid(childrenDetails[index]) : {};
  }, [isOpen]);

  const saveHandler = () => {
    if (!kid.firstName) {
      toast.error(t('toast.enterFirstName'));
      return false;
    }
    if (!kid.lastName) {
      toast.error(t('toast.enterLastName'));
      return false;
    }
    if (!kid.gender) {
      toast.error(t('toast.selectGender'));
      return false;
    }
    if (!kid.dob) {
      toast.error(t('toast.enterDob'));
      return false;
    }
    let updatedChildren = childrenDetails.map((ele) => (ele.id === id ? { ...kid, id } : ele));
    setChildrenDetails(updatedChildren);
    setModalState((previousState) => ({ ...previousState, isOpen: false }));
  };

  return (
    <ReactModal className="children-modal" isOpen={isOpen}>
      <header>
        <h1>{t('customer.editChildrenDetails')}</h1>
        <span
          className="icon-close"
          onClick={() => setModalState((previousState) => ({ ...previousState, isOpen: false }))}>
          <IconClose />
        </span>
      </header>
      <div className="row-container">
        <div className="children-modal__first-name">
          <label>{t('customer.firstName')}</label>
          <input
            defaultValue={firstName}
            onChange={(e) => {
              setKid({ ...kid, firstName: e.target.value });
            }}
          />
        </div>
        <div className="children-modal__last-name">
          <label>{t('customer.lastName')}</label>
          <input
            defaultValue={lastName}
            onChange={(e) => {
              setKid({ ...kid, lastName: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="row-container">
        <div className="children-modal__gender">
          <label>{t('customer.gender')}</label>
          <Select
            className="input-select"
            classNamePrefix="input-select"
            options={genderOptions}
            isSearchable={false}
            value={genderOptions.filter((option) => option.value == gender)}
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={(option) => {
              setKid((previousState) => ({ ...previousState, gender: option.value }));
            }}
          />
        </div>
        <div className="children-modal__dob">
          <label>{t('customer.dob')}</label>
          <input
            type="date"
            id="modal-dob"
            defaultValue=""
            onChange={(e) => setKid({ ...kid, dob: new Date(e.target.value) })}
          />
        </div>
      </div>
      <div className="actions">
        <button className="actions-next-link" onClick={saveHandler}>
          {t('customer.save')}
        </button>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  modalState: PropTypes.object,
  setModalState: PropTypes.func,
  childrenDetails: PropTypes.array,
  setChildrenDetails: PropTypes.func
};
