import { useState } from 'react';
import { Layout, Sidebar, StepBlock } from '../../components/common';
import { KidDetailsForm } from '../../components/AdultDetails/index';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import useCustomerStore from '../../store/customerStore';

export const EnterKidDetails = () => {
  const [children, setChildren] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [privacyCheckbox, setPrivacyCheckbox] = useState({
    privacyPolicy1: false,
    privacyPolicy2: false,
    privacyPolicy3: false
  });
  const [customer, phoneNumber, tableNumber, restaurantName, restaurantEmail, signupTimeStamp] =
    useCustomerStore(
      ({
        customer,
        phoneNumber,
        tableNumber,
        restaurantName,
        restaurantEmail,
        signupTimeStamp
      }) => [customer, phoneNumber, tableNumber, restaurantName, restaurantEmail, signupTimeStamp]
    );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const gender = [
    { label: t('customer.male'), value: 'Male' },
    { label: t('customer.female'), value: 'Female' }
  ];

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      postCode,
      gender,
      dob,
      privacyPolicy1,
      privacyPolicy2,
      privacyPolicy3
    } = customer;

    const data = {
      firstName,
      lastName,
      phoneNumber,
      email,
      postCode,
      gender,
      dob,
      restaurantName,
      privacyPolicy1,
      privacyPolicy2,
      privacyPolicy3,
      signupTimeStamp,
      kids: children
    };

    if (children.length === 0) {
      toast.error(t('toast.noKidsRegistered'));
      setIsLoading(false);
      return;
    }
    if (
      !privacyCheckbox.privacyPolicy1 ||
      !privacyCheckbox.privacyPolicy2
      // !privacyCheckbox.privacyPolicy3
    ) {
      toast.error(t('toast.noConsent'));
      setIsLoading(false);
      return;
    }
    const response = await axios.post('/customer', data);
    if (response.status === 201) {
      const data = { tableNumber, restaurantEmail, kids: children };
      const generatePDF = await axios.post('/customer/generate-pdf', data);
      if (generatePDF.status === 201) {
        toast.success(t('toast.successfulSubmission'));
        setTimeout(() => {
          setIsLoading(false);
          navigate(`/thank-you`, { replace: true });
        }, 2000);
      } else {
        setIsLoading(false);
        toast.error(t('toast.pdfError'));
      }
    } else {
      setIsLoading(false);
      toast.error(t('toast.errorSavingDetails'));
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <form className="layout-form adult-children-details" onSubmit={handleSubmit}>
          <StepBlock
            step={3}
            totalStep={3}
            heading={t('customer.childrenDetails')}
            subHeading={t('customer.childrenSub')}
          />
          <KidDetailsForm
            genderOptions={gender}
            childrenDetails={children}
            setChildrenDetails={setChildren}
            loading={loading}
            privacyCheckbox={privacyCheckbox}
            setPrivacyCheckbox={setPrivacyCheckbox}
          />
        </form>
      </Layout>
    </div>
  );
};
