import * as React from 'react';

const IconEdit = (props) => (
  <svg
    viewBox="0 0 18 18"
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#a)" fill="#fff">
      <path d="M.879 14.34A3 3 0 0 0 0 16.46V18h1.54a3 3 0 0 0 2.12-.879L13.669 7.114l-2.782-2.782L.88 14.339ZM17.358.641a1.966 1.966 0 0 0-2.782 0l-2.63 2.63 2.782 2.782 2.63-2.63a1.967 1.967 0 0 0 0-2.782Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default IconEdit;
