import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconEdit from '../../images/IconEdit';
import IconAdd from '../../images/IconAdd';
import IconDelete from '../../images/IconDelete';
import IconInclinedArrow from '../../images/IconInclinedArrow';
import ViewQrCode from '../Modals/ViewQrCode';
import toast from 'react-hot-toast';
import '../../styles/toast/DeleteToast.scss';
import axios from 'axios';

const RestaurantCard = ({
  isAdd,
  handleNewRestraunt,
  editRestaurant,
  setEditRestaurantInfo,
  restaurantInfo,
  setDeleteRestaurant
}) => {
  const [viewQrModalOpen, setViewQrModalOpen] = useState(false);
  const editRestaurantInfo = () => {
    setEditRestaurantInfo(restaurantInfo);
    editRestaurant(true);
  };
  const { t } = useTranslation();

  const handleAlert = async (deleteRestaurant, toastId) => {
    if (deleteRestaurant) {
      const response = await axios.delete(`restaurant/delete/${restaurantInfo._id}`);
      if (response.data.deletedRestaurant.acknowledged) {
        toast.dismiss(toastId);
        setDeleteRestaurant(false);
        toast.success('Restaurant Deleted!');
      }
    } else {
      toast.dismiss(toastId);
    }
  };

  const deleteRestaurant = () => {
    setDeleteRestaurant(true);
    toast(
      (toast) => (
        <div className="alert-box">
          <h2>{t('admin.deleteRestaurant')}</h2>
          <div className="actions">
            <button className="btn" onClick={() => handleAlert(true, toast.id)}>
              {t('admin.yes')}
            </button>
            <button className="btn" onClick={() => handleAlert(false, toast.id)}>
              {t('admin.no')}
            </button>
          </div>
        </div>
      ),
      {
        position: 'top-center',
        className: 'delete-toast',
        duration: 20000,
        style: {
          border: '1px solid #713200',
          padding: '16px',
          color: '#713200'
        }
      }
    );
  };

  return (
    <>
      {isAdd ? (
        <div className="add-new-card" onClick={handleNewRestraunt}>
          <IconAdd />
          <h3 className="add-new-card-heading">{t('admin.addNewRestaurant')}</h3>
        </div>
      ) : (
        <div className="restaurant-card">
          <div className="restaurant-name">
            <h3>{restaurantInfo.restaurantName}</h3>
            <div className="options">
              <span className="card-option" onClick={editRestaurantInfo}>
                <IconEdit />
              </span>
              <span className="card-option" onClick={deleteRestaurant}>
                <IconDelete />
              </span>
            </div>
          </div>
          <div className="restaurant-email">
            <h5>Email</h5>
            <p>{restaurantInfo.restaurantEmail}</p>
          </div>
          <div className="restaurant-url">
            <div className="url">
              <h5>URL</h5>
              <p>{`${process.env.REACT_APP_ORIGIN}/${restaurantInfo.slug}`}</p>
            </div>
            <div className="share-qr" onClick={() => setViewQrModalOpen(true)}>
              <h5>share QR</h5>
              <IconInclinedArrow />
            </div>
          </div>
          <ViewQrCode
            openModal={viewQrModalOpen}
            setOpenModal={setViewQrModalOpen}
            modalHeading={restaurantInfo.restaurantName}
            qrCodeValue={`${process.env.REACT_APP_ORIGIN}/${restaurantInfo.slug}`}
          />
        </div>
      )}
    </>
  );
};

RestaurantCard.propTypes = {
  isAdd: PropTypes.bool,
  handleNewRestraunt: PropTypes.func,
  editRestaurant: PropTypes.func,
  setEditRestaurantInfo: PropTypes.func,
  restaurantInfo: PropTypes.object,
  setDeleteRestaurant: PropTypes.func
};

export default RestaurantCard;
