import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select from 'react-select';
import { Modal } from '../common';
import { useTranslation } from 'react-i18next';
import PrivacyCheckbox from '../common/PrivacyCheckbox';
import '../../styles/forms/UserDetailsForm.scss';
import IconEdit from '../../images/IconEdit';
import toast from 'react-hot-toast';
import SvgLoader from '../../images/SvgLoader';
import { useRef } from 'react';

export const KidDetailsForm = ({
  genderOptions,
  childrenDetails,
  setChildrenDetails,
  loading,
  privacyCheckbox,
  setPrivacyCheckbox
}) => {
  const [kid, setKid] = useState({
    firstName: '',
    lastName: '',
    gender: 'Male',
    dob: ''
  });
  const [modalState, setModalState] = useState({ isOpen: false });
  const dobRef = useRef(null);
  const { t, i18n } = useTranslation();

  const saveHandler = () => {
    if (!kid.firstName) {
      toast.error(t('toast.enterFirstName'));
      return false;
    }
    if (!kid.lastName) {
      toast.error(t('toast.enterLastName'));
      return false;
    }
    if (!kid.gender) {
      toast.error(t('toast.selectGender'));
      return false;
    }
    if (!kid.dob) {
      toast.error(t('toast.enterDob'));
      return false;
    }
    setChildrenDetails([...childrenDetails, { ...kid, id: childrenDetails.length + 1 }]);
    setKid({ firstName: '', lastName: '', gender: 'Male', dob: '' });
    dobRef.current.value = '';
  };

  const today = new Date();

  //TODO: set kids in state
  return (
    <div className="adult-children-form">
      {childrenDetails.map(({ id, firstName, lastName, gender, dob }, index) => {
        return (
          <div className="children" key={id}>
            <div className="details-section">
              <div className="number">{id}</div>
              <div className="details">
                <h5>
                  {firstName} {lastName}
                </h5>
                <p>
                  {dob?.toLocaleString('default', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                  ,{' '}
                  {gender === 'Male'
                    ? i18n.language === 'it'
                      ? 'Maschio'
                      : 'Male'
                    : i18n.language === 'it'
                    ? 'Femmina'
                    : 'Female'}
                </p>
              </div>
            </div>
            <div
              className="edit-icon"
              onClick={() => {
                setModalState((previousState) => ({
                  ...previousState,
                  index: index,
                  isOpen: true
                }));
              }}>
              <IconEdit />
            </div>
          </div>
        );
      })}
      {childrenDetails.length < 3 && (
        <div>
          <div className="row-container">
            <div className="adult-children-form__first-name">
              <label>{t('customer.firstName')}</label>
              <input
                className="form-input"
                value={kid.firstName}
                onChange={(e) => {
                  setKid((previousState) => ({ ...previousState, firstName: e.target.value }));
                }}
              />
            </div>
            <div className="adult-children-form__last-name">
              <label>{t('customer.lastName')}</label>
              <input
                className="form-input"
                value={kid.lastName}
                onChange={(e) => {
                  setKid((previousState) => ({ ...previousState, lastName: e.target.value }));
                }}
              />
            </div>
          </div>
          <div className="row-container">
            <div className="adult-children-form__gender">
              <label>{t('customer.gender')}</label>
              <Select
                className="input-select"
                classNamePrefix="input-select"
                options={genderOptions}
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={(option) => setKid({ ...kid, gender: option.value })}
              />
            </div>
            <div className="adult-children-form__dob">
              <label>{t('customer.dob')}</label>
              <input
                className="form-input"
                type="date"
                ref={dobRef}
                max={today.toISOString().split('T')[0]}
                onChange={(e) =>
                  setKid((previousState) => ({ ...previousState, dob: new Date(e.target.value) }))
                }
              />
            </div>
          </div>
        </div>
      )}
      {childrenDetails.length < 3 && (
        <div className="row-container">
          <button
            className="save-add-children"
            type="button"
            onClick={() =>
              Object.keys(kid).length === 0 ? toast.error(t('customer.addKids')) : saveHandler()
            }>
            {t('customer.saveAndAddNext')}
          </button>
        </div>
      )}
      <PrivacyCheckbox privacyCheckbox={privacyCheckbox} setPrivacyCheckbox={setPrivacyCheckbox} />
      <div className="actions">
        <button type="submit" className="actions-next-link">
          {loading ? <SvgLoader /> : t('customer.print')}
        </button>
      </div>
      <Modal
        modalState={modalState}
        setModalState={setModalState}
        childrenDetails={childrenDetails}
        setChildrenDetails={setChildrenDetails}
      />
    </div>
  );
};

KidDetailsForm.propTypes = {
  genderOptions: PropTypes.array,
  childrenDetails: PropTypes.array,
  setChildrenDetails: PropTypes.func,
  loading: PropTypes.bool,
  privacyCheckbox: PropTypes.object,
  setPrivacyCheckbox: PropTypes.func
};
