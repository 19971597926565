import { React } from 'react';

const MobileNavIcon = (props) => (
  <svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill="#6D8F81">
      <path d="M24.375 7.075H1.625a1.625 1.625 0 0 1 0-3.25h22.75a1.625 1.625 0 1 1 0 3.25ZM1.625 22.326h22.75a1.625 1.625 0 1 1 0 3.25H1.625a1.625 1.625 0 1 1 0-3.25ZM24.375 16.326H1.625a1.625 1.625 0 1 1 0-3.25h22.75a1.625 1.625 0 1 1 0 3.25Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="matrix(1 0 0 -1 0 26)" d="M0 0h26v26H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default MobileNavIcon;
