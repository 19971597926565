import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import '../../styles/forms/UserDetailsForm.scss';
import IconNextArrow from '../../images/IconNextArrow';
import useCustomerStore from '../../store/customerStore';
export const AdultDetailsForm = ({ genderOptions }) => {
  const [customer, updateCustomer, setTableNumber] = useCustomerStore(
    ({ customer, updateCustomer, setTableNumber }) => [customer, updateCustomer, setTableNumber]
  );

  const { firstName, lastName, dob, email, postCode, tableNumber, gender } = customer;

  const { t } = useTranslation();

  return (
    <div className="adult-children-form">
      <div className="row-container">
        <div className="adult-children-form__first-name">
          <label htmlFor="adult-form-first-name">{t('customer.firstName')}</label>
          <input
            className="form-input"
            id="adult-form-first-name"
            type="text"
            name="firstName"
            defaultValue={firstName}
            onChange={(e) => updateCustomer({ ...customer, firstName: e.target.value })}
            // onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            // onInput={(e) => e.target.setCustomValidity('')}
            // required
          />
        </div>
        <div className="adult-children-form__last-name">
          <label htmlFor="adult-form-last-name">{t('customer.lastName')}</label>
          <input
            className="form-input"
            id="adult-form-last-name"
            type="text"
            name="lastName"
            defaultValue={lastName}
            onChange={(e) => updateCustomer({ ...customer, lastName: e.target.value })}
            // onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            // onInput={(e) => e.target.setCustomValidity('')}
            // required
          />
        </div>
      </div>
      <div className="row-container">
        <div className="adult-children-form__gender">
          <label htmlFor="adult-form-gender">{t('customer.gender')}</label>
          <Select
            className="input-select"
            classNamePrefix="input-select"
            options={genderOptions}
            value={genderOptions.filter((option) => option.value === gender)}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={(option) => updateCustomer({ ...customer, gender: option.value })}
          />
        </div>
        <div className="adult-children-form__dob">
          <label htmlFor="adult-form-dob">{t('customer.dob')}</label>
          <input
            className="form-input"
            id="adult-form-dob"
            type="date"
            name="dob"
            defaultValue={typeof dob === 'string' ? dob.split('T')[0] : ''}
            onChange={(e) => updateCustomer({ ...customer, dob: new Date(e.target.value) })}
          />
        </div>
      </div>
      <div className="row-container">
        <div className="adult-children-form__email">
          <label htmlFor="adult-form-email">{t('customer.email')}</label>
          <input
            className="form-input"
            id="adult-form-email"
            type="email"
            name="email"
            defaultValue={email}
            onChange={(e) => updateCustomer({ ...customer, email: e.target.value })}
            // onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            // onInput={(e) => e.target.setCustomValidity('')}
            // required
          />
        </div>
      </div>
      <div className="row-container">
        <div className="adult-children-form__postal-code">
          <label htmlFor="adult-form-postal-code">{t('customer.postalCode')}</label>
          <input
            className="form-input"
            id="adult-form-postal-code"
            type="text"
            name="postal-code"
            defaultValue={postCode}
            onChange={(e) => updateCustomer({ ...customer, postCode: e.target.value })}
            // onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            // onInput={(e) => e.target.setCustomValidity('')}
            // required
          />
        </div>
        <div className="adult-children-form__table-number">
          <label htmlFor="adult-form-table-number">{t('customer.tableNumber')}</label>
          <input
            className="form-input"
            id="adult-form-table-number"
            type="text"
            name="table-number"
            defaultValue={tableNumber}
            onChange={(e) => {
              updateCustomer({ ...customer, tableNumber: e.target.value });
              setTableNumber(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="actions">
        <button className="actions-next-link" type="submit">
          {t('customer.next')}
          <IconNextArrow />
        </button>
      </div>
    </div>
  );
};

AdultDetailsForm.propTypes = {
  genderOptions: PropTypes.array
};
