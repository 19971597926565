import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Layout, Sidebar, StepBlock } from '../../components/common';
import IconNextArrow from '../../images/IconNextArrow';
import '../../styles/forms/layoutForm.scss';
import SvgLoader from '../../images/SvgLoader';
import useCustomerStore from '../../store/customerStore';

export const EnterOtpForm = () => {
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [customer, phoneNumber, sender, setCustomer, updateCustomer, setSignupTimeStamp] =
    useCustomerStore(
      ({ customer, phoneNumber, sender, setCustomer, updateCustomer, setSignupTimeStamp }) => [
        customer,
        phoneNumber,
        sender,
        setCustomer,
        updateCustomer,
        setSignupTimeStamp
      ]
    );
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (otp) {
      const data =
        sender === 'Sms'
          ? {
              phoneNumber,
              code: otp
            }
          : {
              phoneNumber,
              otp: otp
            };
      const response =
        sender === 'Sms'
          ? await axios.post('/customer/verifyOtp', data)
          : await axios.post('/sms/verifyWhatsappSms', data);
      if (response.data.status === 'OK') {
        const getCustomer = await axios.get(`/customer/${phoneNumber}`);
        if (getCustomer.data.customer != null) {
          const { customer } = getCustomer.data;
          const { kids } = customer;
          setCustomer(
            customer,
            new Date(),
            [...kids].map((ele) => ({ ...ele, dob: new Date(ele.dob) }))
          );
          const signUpTime = new Date();
          setSignupTimeStamp(signUpTime);
          setIsLoading(false);
          navigate('/registered');
        } else {
          const signUpTime = new Date();
          updateCustomer({ ...customer, signupTimeStamp: signUpTime });
          setSignupTimeStamp(signUpTime);
          navigate('/adult-details');
          setIsLoading(false);
        }
      } else {
        console.log(response);
        toast.error(t('customer.enterCorrectOtp'));
        setIsLoading(false);
      }
    } else {
      toast.error(t('customer.enterOtp'));
    }
  };

  const resendOTP = async () => {
    const response =
      sender === 'Sms'
        ? await axios.post('/customer/sendSms', { phoneNumber })
        : await axios.post('/sms/sendWhatsappSms', { phoneNumber });
    if (response.data.status === 'OK') {
      toast.success(t('customer.otpSent'));
    } else {
      toast.error(t('customer.otpCantSend'));
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <div className="layout-form">
          <StepBlock step={1} totalStep={3} heading={t('customer.otp')} />
          <input
            className="form-input"
            type="text"
            name="otp"
            onChange={(e) => setOtp(e.target.value)}
          />
          <div className="actions">
            <button className="actions-next-link" onClick={handleSubmit}>
              {isLoading ? (
                <SvgLoader />
              ) : (
                <span>
                  {t('customer.next')} <IconNextArrow />
                </span>
              )}
            </button>
            <button className="secondary-button" onClick={resendOTP}>
              {t('customer.resendOtp')}
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};
