import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState({});
  const [pdfContent, setPdfContent] = useState({});
  return (
    <CustomerContext.Provider value={{ customer, setCustomer, pdfContent, setPdfContent }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};

CustomerProvider.propTypes = {
  children: PropTypes.element
};
