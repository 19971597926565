import { React } from 'react';

const MapIcon = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.667 5.833a1.666 1.666 0 1 1-3.333 0 1.666 1.666 0 0 1 3.333 0Zm2.458 4.131L10 14 5.882 9.97a5.833 5.833 0 1 1 8.243-.007v.001Zm-.792-4.13a3.333 3.333 0 1 0-6.666 0 3.333 3.333 0 0 0 6.666 0Zm4.89 3.01-1.196-.4a7.457 7.457 0 0 1-1.724 2.7L10 16.333l-5.283-5.167A7.49 7.49 0 0 1 2.7 7.524 2.473 2.473 0 0 0 0 10v8.127l6.653 1.9 6.683-1.666 6.667 1.623v-8.748a2.5 2.5 0 0 0-1.78-2.393v.001Z"
      fill="#4E665C"
    />
  </svg>
);

export default MapIcon;
