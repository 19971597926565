import React from 'react';
import image1 from '../../images/logo1.svg';
import image2 from '../../images/logo2.svg';
import '../../styles/common/sidebar.scss';

export const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={image1} />
        <img src={image2} />
      </div>
    </div>
  );
};
