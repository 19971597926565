import React from 'react';

const IconCheck = (props) => (
  <svg
    viewBox="0 0 13 13"
    width={13}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.578 4.618 4.86 7.9 11.422.87"
      stroke="#fff"
      fill="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCheck;
