import { useState } from 'react';
import { Layout, AdminMenu } from '../../components/common';
import { PropTypes } from 'prop-types';
import Users from './Users';
import QrCodes from './QrCodes';
import Restaurants from './Restaurants';
import MobileNavMenu from '../../components/common/MobileNavMenu';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = ({ type }) => {
  const [openNavMenu, setOpenNavMenu] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      navigate('/admin', { replace: true });
    }
  }, []);

  return (
    <div className="container">
      <AdminMenu openNavMenu={openNavMenu} setOpenNavMenu={setOpenNavMenu} />
      <Layout admin>
        {type === 'restaurants' ? <Restaurants /> : ''}
        {type === 'users' ? <Users /> : ''}
        {type === 'qr-codes' ? <QrCodes /> : ''}
      </Layout>
      <MobileNavMenu openNavMenu={openNavMenu} setOpenNavMenu={setOpenNavMenu} />
    </div>
  );
};

AdminDashboard.propTypes = {
  type: PropTypes.string.isRequired
};

export default AdminDashboard;
