import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantCard from '../../components/common/RestaurantCard';
import '../../styles/AdminPages/Restaurants.scss';
import AddRestaurantModal from '../../components/Modals/AddRestaurantModal';
import axios from 'axios';

const Restraunts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const { t } = useTranslation();
  const [editRestaurantInfo, setEditRestaurantInfo] = useState({
    restaurantName: '',
    restaurantEmail: '',
    slug: ''
  });

  useEffect(() => {
    // JavaScript IIFE used to fetch response from API
    (async () => {
      const response = await axios.get('/restaurant');
      setRestaurants(response.data.restaurants);
    })();
  }, []);

  useEffect(() => {
    if (!isModalOpen || !isEdit || !isDeleted) {
      (async () => {
        const response = await axios.get('/restaurant');
        setRestaurants(response.data.restaurants);
      })();
    }
  }, [isModalOpen, isEdit, isDeleted]);

  return (
    <>
      <div className="restaurant-page-wrapper">
        <h1 className="heading">{t('admin.restaurants')}</h1>
        <div className="restaurants-card-wrapper">
          <RestaurantCard isAdd={true} handleNewRestraunt={() => setIsModalOpen(true)} />
          {restaurants.map((restaurant) => (
            <RestaurantCard
              key={restaurant._id}
              editRestaurant={setIsEdit}
              setEditRestaurantInfo={setEditRestaurantInfo}
              restaurantInfo={restaurant}
              setDeleteRestaurant={setIsDeleted}
            />
          ))}
        </div>
      </div>
      <AddRestaurantModal
        openModal={isModalOpen}
        setOpenModal={setIsModalOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        restaurantInfo={editRestaurantInfo}
        setRestaurantInfo={setEditRestaurantInfo}
      />
    </>
  );
};

export default Restraunts;
