import React from 'react';
import { Layout, Sidebar } from '../../components/common';
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <div className="not-found">
          <p>{t('customer.notFound')}</p>
        </div>
      </Layout>
    </div>
  );
}

export { NotFound };
