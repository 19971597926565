import { React } from 'react';

const LogoutIcon = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.997 0c.69 0 1.25.56 1.25 1.25v4.164a1.25 1.25 0 1 1-2.5 0V1.249C8.748.56 9.308 0 9.998 0Z"
      fill="#4E665C"
    />
    <path
      d="M12.912 4.114c-.006.42.205.812.559 1.038a6.663 6.663 0 1 1-6.963.01c.36-.227.577-.622.574-1.047a1.25 1.25 0 0 0-1.916-1.06 9.161 9.161 0 1 0 9.668 0 1.253 1.253 0 0 0-1.922 1.06Z"
      fill="#4E665C"
    />
  </svg>
);

export default LogoutIcon;
