import * as React from 'react';

const IconInclinedArrow = (props) => (
  <svg
    viewBox="0 0 11 11"
    width={11}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.692 1a.5.5 0 0 0-.5-.5h-4.5a.5.5 0 0 0 0 1h4v4a.5.5 0 1 0 1 0V1Zm-9.338 9.546 9.192-9.192-.707-.708L.646 9.84l.708.707Z"
      fill="#fff"
    />
  </svg>
);

export default IconInclinedArrow;
