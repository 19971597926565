import { React } from 'react';

const UserIcon = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.25 10.833a3.75 3.75 0 1 1 0-7.499 3.75 3.75 0 0 1 0 7.5ZM11.667 20H.833A.833.833 0 0 1 0 19.167v-.417a6.25 6.25 0 0 1 12.5 0v.417a.833.833 0 0 1-.833.833Zm2.916-12.5a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5ZM13.4 9.184a5.687 5.687 0 0 0-3.891 2.36 7.948 7.948 0 0 1 4.095 4.29h5.564A.834.834 0 0 0 20 15v-.032a5.84 5.84 0 0 0-6.6-5.784Z"
      fill="#4E665C"
    />
  </svg>
);

export default UserIcon;
