import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'react-custom-checkbox';
import IconCheck from '../../images/IconCheck';
import { useTranslation, Trans } from 'react-i18next';
import '../../styles/common/privacyCheckbox.scss';
import useCustomerStore from '../../store/customerStore';

const PrivacyCheckbox = ({ setPrivacyCheckbox }) => {
  const { t } = useTranslation();

  const [customer, updateCustomer] = useCustomerStore(({ customer, updateCustomer }) => [
    customer,
    updateCustomer
  ]);

  return (
    <div className="privacy-checkbox">
      <div className="checkbox-wrapper">
        <Checkbox
          icon={<IconCheck />}
          checked={false}
          className="checkbox-input"
          borderRadius={5}
          borderWidth={2}
          borderColor={'#fff'}
          // label={<Func />}
          onChange={(e) => {
            setPrivacyCheckbox((prevValue) => ({
              ...prevValue,
              privacyPolicy1: e,
              privacyPolicy2: e,
              privacyPolicy3: e
            }));
            updateCustomer({
              ...customer,
              privacyPolicy1: e,
              privacyPolicy2: e,
              privacyPolicy3: e
            });
          }}
        />
        <div className="labels">
          <span>
            <Trans>
              <span className="label">
                {t('customer.privacyPolicy1')}
                <a
                  href={'https://www.iubenda.com/privacy-policy/93065553'}
                  target="_blank"
                  rel="noreferrer"
                />
                <a
                  href={'https://www.iubenda.com/privacy-policy/93065553/cookie-policy'}
                  target="_blank"
                  rel="noreferrer"
                />
              </span>
            </Trans>
          </span>
        </div>
      </div>
      {/* <div className="checkbox-wrapper">
        <Checkbox
          icon={<IconCheck />}
          checked={false}
          className="checkbox-input"
          borderRadius={5}
          borderWidth={2}
          borderColor={'#fff'}
          // label={t('customer.privacyPolicy2')}
          onChange={(e) => {
            setPrivacyCheckbox((prevValue) => ({ ...prevValue, privacyPolicy2: e }));
            updateCustomer({ ...customer, privacyPolicy2: e });
          }}
        />
        <div className="labels">
          <span>
            <span className="label">{t('customer.privacyPolicy2')}</span>
          </span>
        </div>
      </div> */}
      {/* <div className="checkbox-wrapper">
        <Checkbox
          icon={<IconCheck />}
          checked={false}
          className="checkbox-input"
          borderRadius={5}
          borderWidth={2}
          borderColor={'#fff'}
          // label={t('customer.privacyPolicy3')}
          onChange={(e) => {
            setPrivacyCheckbox((prevValue) => ({ ...prevValue, privacyPolicy3: e }));
            updateCustomer({ ...customer, privacyPolicy3: e });
          }}
        />
        <div className="labels">
          <span>
            <span className="label">{t('customer.privacyPolicy3')}</span>
          </span>
        </div>
      </div> */}
    </div>
  );
};

PrivacyCheckbox.propTypes = {
  privacyCheckbox: PropTypes.object,
  setPrivacyCheckbox: PropTypes.func
};

export default PrivacyCheckbox;
