import create from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  phoneNumber: '',
  sender: '',
  restaurantName: '',
  restaurantEmail: '',
  slug: '',
  customer: {
    privacyPolicy1: false,
    privacyPolicy2: false,
    privacyPolicy3: false
  },
  kids: [],
  signupTimeStamp: '',
  tableNumber: ''
};
const useCustomerStore = create(
  persist(
    (set) => ({
      ...initialState,
      setPhoneNumber: (phoneNumber) => set(() => ({ phoneNumber: phoneNumber })),
      updateInfo: ({ sender, restaurantName, restaurantEmail, slug }) =>
        set(() => ({
          sender: sender,
          restaurantName: restaurantName,
          restaurantEmail: restaurantEmail,
          slug: slug
        })),
      updateCustomer: (customer) =>
        set((state) => ({ customer: { ...state.customer, ...customer } })),
      setCustomer: (customer, signupTimeStamp = '', kids = []) =>
        set(() => ({ customer: customer, signupTimeStamp: signupTimeStamp, kids: kids })),
      setTableNumber: (tableNumber) => set(() => ({ tableNumber: tableNumber })),
      setSignupTimeStamp: (signupTimeStamp) => set(() => ({ signupTimeStamp: signupTimeStamp })),
      reset: () => {
        set(initialState);
      }
    }),
    {
      name: 'customer', // unique name
      getStorage: () => localStorage // (optional) by default the 'localStorage' is used
    }
  )
);

export default useCustomerStore;
