import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import image1 from '../../images/logo1.svg';
import image2 from '../../images/logo2.svg';
import '../../styles/common/sideMenu.scss';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '../../images/LogoutIcon';
import QrCodeIcon from '../../images/QrCode';
import UserIcon from '../../images/UserIcon';
import MapIcon from '../../images/MapIcon';
import MobileNavIcon from '../../images/MobileNavIcon';

export const AdminMenu = ({ openNavMenu, setOpenNavMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin', { replace: true });
  };

  return (
    <div className="sidemenu">
      <div className="sidebar-logo">
        <img src={image1} height="18" width="107" />
        <img src={image2} height="13" width="91" />
      </div>

      <nav className="sidemenu-links">
        <ul>
          <li>
            <NavLink
              to="/admin/restaurants"
              className={({ isActive }) => '' + (isActive ? 'active' : '')}>
              <MapIcon /> {t('admin.restaurants')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/users"
              className={({ isActive }) => '' + (isActive ? 'active' : '')}>
              <UserIcon /> {t('admin.users')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/qr-codes"
              className={({ isActive }) => '' + (isActive ? 'active' : '')}>
              <QrCodeIcon /> {t('admin.qrCodes')}
            </NavLink>
          </li>
        </ul>
        <button className="logout-button" onClick={handleLogout}>
          <LogoutIcon /> {t('admin.logout')}
        </button>
      </nav>
      <button type="button" className="mobileNavIcon" onClick={() => setOpenNavMenu(!openNavMenu)}>
        <MobileNavIcon />
      </button>
    </div>
  );
};

AdminMenu.propTypes = {
  openNavMenu: PropTypes.bool,
  setOpenNavMenu: PropTypes.func
};
