import React from 'react';

const IconNextArrow = (props) => (
  <svg
    viewBox="0 0 19 19"
    width={19}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1 7a1 1 0 0 0 0 2V7Zm17.707 1.707a1 1 0 0 0 0-1.414L12.343.929a1 1 0 1 0-1.414 1.414L16.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM1 9h17V7H1v2Z"
      fill="#fff"
    />
  </svg>
);

export default IconNextArrow;
