import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AdminUserRow from '../../components/common/AdminUserRow';
import { useTranslation } from 'react-i18next';
import '../../styles/AdminPages/Users.scss';
import '../../styles/forms/layoutForm.scss';

function Users() {
  const [query, setQuery] = useState({ limit: 10, skip: 0 });
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    // JavaScript IIFE used to fetch response from API
    (async () => {
      const response = await axios.get(`/admin/customers?limit=${query.limit}&skip=${query.skip}`);
      setUsers(response.data.customers);
    })();
  }, [page]);

  const handleNextClick = () => {
    let updatedQuery = query;
    query.skip += 5;
    setPage((prev) => prev + 1);
    setQuery(updatedQuery);
  };

  const handlePrevClick = () => {
    let updatedQuery = query;
    query.skip -= 5;
    setPage((prev) => prev - 1);
    setQuery(updatedQuery);
  };
  return (
    <div className="users-wrapper">
      <h1 className="layout-heading">{t('admin.users')}</h1>
      <div className="user-details-wrapper">
        {users.map(({ _id, firstName, lastName, email, phoneNumber }) => (
          <AdminUserRow
            key={_id}
            restaurantName="Benvenuto Via Wittgens, 6/B - Piazza Vetra, 20123 Milan"
            name={`${firstName} ${lastName}`}
            email={email}
            loginTime="04:55 PM"
            phoneNumber={phoneNumber}
          />
        ))}
      </div>
      <div className="btn-grp">
        <button
          className="actions-next-link"
          type="submit"
          onClick={handlePrevClick}
          disabled={!page}>
          {t('admin.prev')}
        </button>
        <button className="actions-next-link" type="submit" onClick={handleNextClick}>
          {t('admin.next')}
        </button>
      </div>
    </div>
  );
}

export default Users;
