import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Sidebar } from '../../components/common';
import { AddModal } from '../../components/common';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import '../../styles/forms/SelectChildren.scss';
import Checkbox from 'react-custom-checkbox';
import IconCheck from '../../images/IconCheck';
import IconAdd from '../../images/IconAdd';
import toast from 'react-hot-toast';
import PrivacyCheckbox from '../../components/common/PrivacyCheckbox';
import SvgLoader from '../../images/SvgLoader';
import useCustomerStore from '../../store/customerStore';

export const SelectChildren = () => {
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [modalState, setModalState] = useState({ isOpen: false });
  const [isLoading, setIsLoading] = useState(false);

  const [
    customer,
    kids,
    phoneNumber,
    tableNumber,
    restaurantName,
    restaurantEmail,
    signupTimeStamp,
    updateCustomer,
    setSignupTimeStamp
  ] = useCustomerStore(
    ({
      customer,
      kids,
      phoneNumber,
      tableNumber,
      restaurantName,
      restaurantEmail,
      signupTimeStamp,
      updateCustomer,
      setSignupTimeStamp
    }) => [
      customer,
      kids,
      phoneNumber,
      tableNumber,
      restaurantName,
      restaurantEmail,
      signupTimeStamp,
      updateCustomer,
      setSignupTimeStamp
    ]
  );
  const { t, i18n } = useTranslation();
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const [childrenDetails, setChildrenDetails] = useState(
    [...customer.kids].map((ele, index) => ({ ...ele, id: index + 1 }))
  );

  const [checkboxState, setCheckboxState] = useState(Array(childrenDetails.length).fill(false));
  const [privacyCheckbox, setPrivacyCheckbox] = useState({
    privacyPolicy1: false,
    privacyPolicy2: false,
    privacyPolicy3: false
  });

  useEffect(() => {
    if (submit) {
      (async () => {
        if (customer.gender === '') {
          console.log('hey');
          delete customer.gender;
        }
        if (customer.signupTimeStamp === '') {
          customer.signupTimeStamp = new Date();
        }

        if (signupTimeStamp === '') {
          setSignupTimeStamp(new Date());
        }
        const response = await axios.put(`/customer/${phoneNumber}`, {
          signupTimeStamp: signupTimeStamp !== '' ? signupTimeStamp : new Date(),
          ...customer,
          selectedChildren,
          restaurantName
        });
        if (response.status === 200) {
          const data = {
            tableNumber,
            restaurantEmail,
            kids: selectedChildren
          };
          const generatePDF = await axios.post('customer/generate-pdf', data);
          if (generatePDF.status === 201) {
            toast.success(t('toast.successfulSubmission'));
            setTimeout(() => {
              setIsLoading(false);
              navigate('/thank-you', { replace: true });
            }, 2000);
          } else {
            setIsLoading(false);
            toast.error(t('toast.pdfError'));
          }
        } else {
          setIsLoading(false);
          toast.error(t('toast.errorSubmitting'));
        }
        setSubmit(false);
      })();
    }
  }, [submit]);

  function getAge(dob) {
    const today = new Date();
    const bd = new Date(dob);
    let age = today.getFullYear() - bd.getFullYear();
    const m = today.getMonth() - bd.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < bd.getDate())) {
      age--;
      if (age < 0) return 0;
    }
    return age;
  }

  const handleChange = (e, id, indexp) => {
    const selectedChild = childrenDetails.find((node) => node.id === id);
    console.log(selectedChild);
    if (selectedChildren.find((node) => node.id === id)) {
      const filteredSelectedChildren = selectedChildren.filter((node) => node.id !== id);
      setSelectedChildren(filteredSelectedChildren);
    } else {
      if (selectedChildren.length === 3) {
        toast.error(t('customer.chooseChildrenToast'));
        setCheckboxState((prevState) =>
          prevState.map((ele, index) => (index !== indexp ? ele : !ele))
        );
      } else {
        setSelectedChildren([...selectedChildren, selectedChild]);
      }
    }
  };

  const handleSubmit = async () => {
    if (selectedChildren.length === 0) {
      toast.error(t('toast.noKidsSelected'));
      setIsLoading(false);
      return;
    }
    if (
      !privacyCheckbox.privacyPolicy1 ||
      !privacyCheckbox.privacyPolicy2
      // !privacyCheckbox.privacyPolicy3
    ) {
      toast.error(t('toast.noConsent'));
      setIsLoading(false);
      return;
    }
    updateCustomer({ ...customer, kids: childrenDetails });
    setIsLoading(true);
    setSubmit(true);
  };

  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <div className="select-children">
          <header>
            <h1>{t('customer.chooseChildren')}</h1>
            <span>{t('customer.chooseChildrenSub')}</span>
          </header>
          <div className="children-row-container">
            {childrenDetails.map(({ id, firstName, lastName, gender, dob }, index) => {
              return (
                <div className="children-container" key={id}>
                  <Checkbox
                    icon={<IconCheck />}
                    checked={checkboxState[index]}
                    className="checkbox-input"
                    borderRadius={5}
                    borderWidth={0}
                    onChange={(e) => {
                      setCheckboxState((prevState) =>
                        prevState.map((ele, indexp) => (indexp === index ? !ele : ele))
                      );
                      handleChange(e, id, index);
                    }}
                  />
                  <p className="children-name">
                    {firstName} {lastName}
                  </p>
                  <p className="children-other-details">
                    {getAge(dob)} {t('customer.year')}
                  </p>
                  <p className="children-other-details">
                    {gender === 'Male'
                      ? i18n.language === 'it'
                        ? 'Maschio'
                        : 'Male'
                      : i18n.language === 'it'
                      ? 'Femmina'
                      : 'Female'}
                  </p>
                </div>
              );
            })}

            {selectedChildren.length < 3 && (
              <div className="add-new-children" onClick={() => setModalState({ isOpen: true })}>
                <IconAdd />
                <p>{t('customer.addChildren')}</p>
              </div>
            )}
          </div>
          <PrivacyCheckbox
            privacyCheckbox={privacyCheckbox}
            setPrivacyCheckbox={setPrivacyCheckbox}
          />
          {selectedChildren.length <= 3 && (
            <div className="actions">
              <button type="submit" className="actions-next-link" onClick={handleSubmit}>
                {isLoading ? <SvgLoader /> : <span>{t('customer.print')}</span>}
              </button>
            </div>
          )}
          <AddModal
            modalState={modalState}
            setModalState={setModalState}
            childrenDetails={childrenDetails}
            setChildrenDetails={setChildrenDetails}
            checkboxState={checkboxState}
            setCheckboxState={setCheckboxState}
          />
        </div>
      </Layout>
    </div>
  );
};
