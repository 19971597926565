import React from 'react';
import image1 from '../../images/logo1.svg';
import image2 from '../../images/logo2.svg';
import '../../styles/Home/home.scss';

const Home = () => {
  return (
    <div className="home">
      <div className="home-logo">
        <img src={image1} />
        <img src={image2} />
      </div>
    </div>
  );
};

export default Home;
