import React, { useState, useEffect } from 'react';
import '../../styles/AdminPages/QrCodes.scss';
import QrCodescolumn from '../../components/common/QrCodescolumn';
import axios from 'axios';

function QrCodes() {
  const [restaurant, setRestaurant] = useState([]);

  useEffect(() => {
    // JavaScript IIFE used to fetch response from API
    (async () => {
      const response = await axios.get('/restaurant');
      setRestaurant(response.data.restaurants);
    })();
  }, []);

  return (
    <>
      <div className="qr-codes-page-wrapper">
        <h2 className="heading">QR Codes</h2>
        <div className="codes-wrapper">
          {restaurant.map(({ _id, restaurantName, restaurantEmail, slug }) => (
            <QrCodescolumn key={_id} name={restaurantName} email={restaurantEmail} slug={slug} />
          ))}
        </div>
      </div>
    </>
  );
}

export default QrCodes;
