import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReactModal from 'react-modal';
import IconClose from '../../images/IconClose';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { exportComponentAsJPEG } from 'react-component-export-image';
function ViewQrCode({ openModal, setOpenModal, modalHeading, qrCodeValue }) {
  const componentRef = useRef();
  const { t } = useTranslation();
  return (
    <>
      <ReactModal className="admin-modal" isOpen={openModal}>
        <div className="modal-head">
          <span className="modal-close-icon" onClick={() => setOpenModal(false)}>
            <IconClose />
          </span>
        </div>
        <div className="modal-content" ref={componentRef}>
          <h2>{modalHeading}</h2>
          <QRCode value={qrCodeValue} fgColor="#34443D" viewBox={`0 0 250 250`} size={250} />
        </div>
        <button className="save-code" onClick={() => exportComponentAsJPEG(componentRef)}>
          {t('admin.save')}
        </button>
      </ReactModal>
    </>
  );
}

export default ViewQrCode;

ViewQrCode.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  modalHeading: PropTypes.string,
  qrCodeValue: PropTypes.string
};
