import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import IconClose from '../../images/IconClose';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

function AddRestaurantModal({
  openModal,
  setOpenModal,
  isEdit,
  setIsEdit,
  restaurantInfo,
  setRestaurantInfo
}) {
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      ...restaurantInfo,
      slug: restaurantInfo.restaurantName.toLowerCase().replace(' ', '-')
    };
    if (!isEdit) {
      const response = await axios.post('/restaurant', data);
      if (response.status === 201) {
        toast.success(t('toast.restaurantCreated'));
      } else {
        toast.error(t('toast.errorCreatingRestaurant'));
      }
      setOpenModal(false);
    } else {
      const response = await axios.put(`/restaurant/${restaurantInfo._id}`, data);
      if (response.status === 200) {
        toast.success(t('toast.restaurantUpdated'));
      } else {
        toast.error(t('toast.errorUpdatingRestaurant'));
      }
      setIsEdit(false);
    }
  };

  const HandleCloseModal = () => {
    setOpenModal(false);
    setIsEdit(false);
    setRestaurantInfo({ restaurantName: '', restaurantEmail: '', slug: '' });
  };

  return (
    <ReactModal className="add-restaurant-modal-wrapper" isOpen={openModal || isEdit}>
      <form onSubmit={handleSubmit}>
        <div className="add-restaurant-form">
          <div className="add-resturant-modal-head">
            <h2>{isEdit ? t('admin.editRestaurant') : t('admin.addNewRestaurant')}</h2>
            <span className="modal-close-icon" onClick={HandleCloseModal}>
              <IconClose />
            </span>
          </div>
          <label htmlFor=""> {t('admin.restaurantName')}:</label>
          <input
            type="text"
            name="restaurantName"
            value={restaurantInfo.restaurantName}
            onChange={(e) =>
              setRestaurantInfo({ ...restaurantInfo, restaurantName: e.target.value })
            }
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
          <label htmlFor=""> {t('admin.email')}:</label>
          <input
            type="text"
            name="restaurantEmail"
            value={restaurantInfo.restaurantEmail}
            onChange={(e) =>
              setRestaurantInfo({ ...restaurantInfo, restaurantEmail: e.target.value })
            }
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
          <div className="actions">
            <button type="submit" className="actions-next-link">
              {t('admin.save')}
            </button>
          </div>
        </div>
      </form>
    </ReactModal>
  );
}

export default AddRestaurantModal;

AddRestaurantModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  restaurantInfo: PropTypes.object,
  setRestaurantInfo: PropTypes.func
};
