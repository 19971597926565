import PropTypes from 'prop-types';
import React from 'react';
import '../../styles/common/mobileNavMenu.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import MapIcon from '../../images/MapIcon';
import UserIcon from '../../images/UserIcon';
import QrCodeIcon from '../../images/QrCode';
import LogoutIcon from '../../images/LogoutIcon';
import MobileNavIcon from '../../images/MobileNavIcon';

function MobileNavMenu({ openNavMenu, setOpenNavMenu }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin', { replace: true });
  };
  return (
    <ReactModal className="mobile-nav-menu" isOpen={openNavMenu}>
      <nav>
        <div className="nav-menu-header">
          <span onClick={() => setOpenNavMenu(false)}>
            <MobileNavIcon />
          </span>
        </div>
        <ul>
          <li>
            <NavLink
              to="/admin/restaurants"
              className={({ isActive }) => '' + (isActive ? 'active' : '')}>
              <MapIcon /> {t('admin.restaurants')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/users"
              className={({ isActive }) => '' + (isActive ? 'active' : '')}>
              <UserIcon /> {t('admin.users')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/qr-codes"
              className={({ isActive }) => '' + (isActive ? 'active' : '')}>
              <QrCodeIcon /> {t('admin.qrCodes')}
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="logout-link">
        <button className="logout-button" onClick={handleLogout}>
          {' '}
          <LogoutIcon /> {t('admin.logout')}
        </button>
      </div>
    </ReactModal>
  );
}

export default MobileNavMenu;

MobileNavMenu.propTypes = {
  openNavMenu: PropTypes.bool,
  setOpenNavMenu: PropTypes.func
};
