import { React } from 'react';

const QrCodeIcon = (props) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.875 5.875A.625.625 0 0 0 1.5 5.25V2.125a.625.625 0 0 1 .625-.625h3.438a.625.625 0 0 0 0-1.25H2.124A1.875 1.875 0 0 0 .25 2.125V5.25a.625.625 0 0 0 .625.625Zm15-5.625h-3.438a.625.625 0 1 0 0 1.25h3.438a.625.625 0 0 1 .625.625V5.25a.625.625 0 1 0 1.25 0V2.125A1.875 1.875 0 0 0 15.875.25Zm1.25 11.875a.624.624 0 0 0-.625.625v3.125a.624.624 0 0 1-.625.625h-3.438a.624.624 0 1 0 0 1.25h3.438a1.875 1.875 0 0 0 1.875-1.875V12.75a.624.624 0 0 0-.625-.625ZM5.562 16.5H2.126a.625.625 0 0 1-.625-.625V12.75a.625.625 0 1 0-1.25 0v3.125a1.875 1.875 0 0 0 1.875 1.875h3.438a.625.625 0 1 0 0-1.25Zm11.563-8.125H.875a.625.625 0 0 0 0 1.25h16.25a.625.625 0 1 0 0-1.25Z"
      fill="#4E665C"
    />
  </svg>
);

export default QrCodeIcon;
