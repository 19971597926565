import React from 'react';

const IconAdd = (props) => (
  <svg
    viewBox="0 0 27 27"
    width={27}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M26.08 12.064H14.624V.608h-3.136v11.456H.032v3.072h11.456v11.456h3.136V15.136H26.08v-3.072Z"
      fill="#fff"
    />
  </svg>
);

export default IconAdd;
