import { useState } from 'react';
import { Layout, Sidebar } from '../../components/common';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../styles/forms/RegisteredUser.scss';
import { AdultModal } from '../../components/common/AdultModal';
import IconEdit from '../../images/IconEdit';
import IconNextArrow from '../../images/IconNextArrow';
import toast from 'react-hot-toast';
import useCustomerStore from '../../store/customerStore';

export const RegisteredUser = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const [customer, tableNumber, updateCustomer, setTableNumber] = useCustomerStore(
    ({ customer, tableNumber, updateCustomer, setTableNumber }) => [
      customer,
      tableNumber,
      updateCustomer,
      setTableNumber
    ]
  );
  const { t, i18n } = useTranslation();
  const clickHandler = (e) => {
    e.preventDefault();
    if (!tableNumber) {
      toast.error(t('toast.enterTableNumber'));
      return false;
    }
    if (showGender === '') {
      toast.error(t('toast.fillGender'));
      return false;
    }
    if (!dob) {
      toast.error(t('toast.enterDob'));
      return false;
    }
    navigate('/registered/kids');
  };

  const { firstName, lastName, gender, dob, email, postCode } = customer;

  let showGender = '';
  if (gender === 'Male') {
    showGender = i18n.language === 'it' ? 'Maschio' : 'Male';
  } else if (gender === 'Female') {
    showGender = i18n.language === 'it' ? 'Femmina' : 'Female';
  }
  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <form className="layout-form registered-user">
          <h1>
            {t('customer.welcome')}, <span>{firstName}</span>
          </h1>
          <label>{t('customer.tableNumber')}</label>
          <input
            className="form-input"
            type="text"
            value={tableNumber}
            onChange={(e) => {
              setTableNumber(e.target.value);
            }}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
          <div className="adult-details">
            <div className="details-section">
              <p className="adult-name-gender">
                {firstName} {lastName}, {showGender}
              </p>
              <p className="other-details">{email}</p>
              <p className="other-details">
                {t('customer.postalCode')} {postCode}
              </p>
            </div>
            <div
              className="edit-icon"
              onClick={() => {
                setModalOpen(true);
              }}>
              <IconEdit />
            </div>
          </div>
          <div className="actions">
            <button className="actions-next-link" onClick={clickHandler}>
              {t('customer.next')}
              <IconNextArrow />
            </button>
          </div>
          <AdultModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            customer={customer}
            updateCustomer={updateCustomer}
          />
        </form>
      </Layout>
    </div>
  );
};
