import PropTypes from 'prop-types';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import ViewQrCode from '../Modals/ViewQrCode';
function QrCodescolumn({ name, email, slug }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="code-details" onClick={() => setModalOpen(true)}>
        <div className="qr-code">
          <QRCode
            value={`${process.env.REACT_APP_ORIGIN}/${slug}`}
            viewBox={`0 0 160 160`}
            size={160}
            bgColor="#00000000"
            fgColor="#34443D"
          />
        </div>
        <div className="restaurant-details">
          <h2>{name}</h2>
          <h3>Email</h3>
          <p>{email}</p>
          <h3>URL</h3>
          <p>{`${process.env.REACT_APP_ORIGIN}/${slug}`}</p>
        </div>
      </div>
      <ViewQrCode
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        modalHeading={name}
        qrCodeValue={`${process.env.REACT_APP_ORIGIN}/${slug}`}
      />
    </>
  );
}

export default QrCodescolumn;

QrCodescolumn.propTypes = {
  setOpenModal: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  slug: PropTypes.string
};
