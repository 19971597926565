import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');
import '../../styles/forms/RegisteredUser.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import IconClose from '../../images/IconClose';
import toast from 'react-hot-toast';
import useCustomerStore from '../../store/customerStore';
export const AdultModal = ({
  modalOpen,
  setModalOpen,
  customer: { firstName, lastName, email, dob, postCode, gender },
  updateCustomer
}) => {
  const { t } = useTranslation();
  const customer = useCustomerStore(({ customer }) => customer);
  const genderOptions = [
    { label: t('customer.male'), value: 'Male' },
    { label: t('customer.female'), value: 'Female' }
  ];

  const today = new Date();
  const handleModalClose = () => {
    if (!firstName) {
      toast.error(t('toast.enterFirstName'));
      return false;
    }
    if (!lastName) {
      toast.error(t('toast.enterLastName'));
      return false;
    }
    if (!email) {
      toast.error(t('toast.enterEmail'));
      return false;
    }
    if (!dob) {
      toast.error(t('toast.enterDob'));
      return false;
    }
    if (!postCode) {
      toast.error(t('toast.enterPostalCode'));
      return false;
    }
    if (!gender) {
      toast.error(t('toast.selectGender'));
      return false;
    }
    setModalOpen(false);
  };

  return (
    <ReactModal className="adult-modal" isOpen={modalOpen}>
      <header>
        <h1>{t('customer.editAdultDetails')}</h1>
        <span className="icon-close" onClick={() => setModalOpen(false)}>
          <IconClose />
        </span>
      </header>
      <div className="row-container">
        <div className="adult-modal__first-name">
          <label>{t('customer.firstName')}</label>
          <input
            defaultValue={firstName}
            onChange={(e) => {
              updateCustomer({ ...customer, firstName: e.target.value });
            }}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
        </div>
        <div className="adult-modal__last-name">
          <label>{t('customer.lastName')}</label>
          <input
            defaultValue={lastName}
            onChange={(e) => {
              updateCustomer({ ...customer, lastName: e.target.value });
            }}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
        </div>
      </div>
      <div className="row-container">
        <div className="adult-modal__gender">
          <label>{t('customer.gender')}</label>
          <Select
            className="input-select"
            classNamePrefix="input-select"
            options={genderOptions}
            value={genderOptions.filter((option) => option.value === gender)}
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={(option) => {
              updateCustomer({ ...customer, gender: option.value });
            }}
          />
        </div>
        <div className="adult-modal__dob">
          <label>{t('customer.dob')}</label>
          <input
            name="dob"
            type="date"
            defaultValue={dob ? dob.split('T')[0] : ''}
            max={today.toISOString().split('T')[0]}
            onChange={(e) => {
              updateCustomer({ ...customer, dob: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="row-container">
        <div className="adult-modal__email">
          <label>{t('customer.email')}</label>
          <input
            name="email"
            type="email"
            defaultValue={email}
            onChange={(e) => {
              updateCustomer({ ...customer, email: e.target.value });
            }}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
        </div>
        <div className="adult-modal__postal-code">
          <label>{t('customer.postalCode')}</label>
          <input
            name="postalCode"
            defaultValue={postCode}
            onChange={(e) => {
              updateCustomer({ ...customer, postCode: e.target.value });
            }}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
        </div>
      </div>
      <div className="actions">
        <button className="actions-next-link" onClick={handleModalClose}>
          {t('customer.save')}
        </button>
      </div>
    </ReactModal>
  );
};

AdultModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  customer: PropTypes.object,
  updateCustomer: PropTypes.func
};
