import { Layout, Sidebar, StepBlock } from '../../components/common';
import { AdultDetailsForm } from '../../components/AdultDetails/index';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import useCustomerStore from '../../store/customerStore';
export const EnterAdultDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const customer = useCustomerStore((state) => state.customer);
  const gender = [
    { label: t('customer.male'), value: 'Male' },
    { label: t('customer.female'), value: 'Female' }
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const { firstName, lastName, email, dob, gender, postCode, tableNumber } = customer;

    if (!firstName) {
      toast.error(t('toast.enterFirstName'));
      return false;
    }
    if (!lastName) {
      toast.error(t('toast.enterLastName'));
      return false;
    }
    if (!email) {
      toast.error(t('toast.enterEmail'));
      return false;
    }
    if (!dob) {
      toast.error(t('toast.enterDob'));
      return false;
    }
    if (!postCode) {
      toast.error(t('toast.enterPostalCode'));
      return false;
    }
    if (!gender) {
      toast.error(t('toast.selectGender'));
      return false;
    }
    if (!tableNumber) {
      toast.error(t('toast.enterTableNumber'));
      return false;
    }
    navigate('/kid-details');
  };

  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <form className="layout-form adult-children-details" onSubmit={handleSubmit}>
          <StepBlock step={2} totalStep={3} heading={t('customer.adultDetails')} />
          <AdultDetailsForm genderOptions={gender} />
        </form>
      </Layout>
    </div>
  );
};
