// import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { EnterPhoneForm } from './pages/Auth/EnterPhoneForm';
import { EnterOtpForm } from './pages/Auth/EnterOtpForm';
import { EnterAdultDetails } from './pages/AdultDetails/EnterAdultDetails';
import { EnterKidDetails } from './pages/AdultDetails/EnterKidDetails';
import { RegisteredUser } from './pages/ExistingUser/RegisteredUser';
import { SelectChildren } from './pages/ExistingUser/SelectChildren';
import { ThankYou } from './pages/ThankYou/ThankYou';
import { NotFound } from './pages/NotFound/NotFound';
import axios from 'axios';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import AdminLogin from './pages/Auth/AdminLogin';
import Home from './pages/Home/Home';

// set default baseURL to call backend
axios.defaults.baseURL = process.env.REACT_APP_BACKEND;

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:slug" element={<EnterPhoneForm />} />
        <Route path="/verify-otp" element={<EnterOtpForm />} />
        <Route path="/adult-details" element={<EnterAdultDetails />} />
        <Route path="/kid-details" element={<EnterKidDetails />} />
        <Route path="/registered" element={<RegisteredUser />} />
        <Route path="/registered/kids" element={<SelectChildren />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/restaurants" element={<AdminDashboard type="restaurants" />} />
        <Route path="/admin/users" element={<AdminDashboard type="users" />} />
        <Route path="/admin/qr-codes" element={<AdminDashboard type="qr-codes" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster position="bottom-right" toastOptions={{ duration: 2000 }} />
    </>
  );
}

export default App;
