import React from 'react';
import { PropTypes } from 'prop-types';
import '../../styles/common/stepBlock.scss';

const StepBlock = ({ step, totalStep, heading, subHeading }) => {
  return (
    <div className="step-block">
      <p className="step">
        STEP {step}/{totalStep}:
      </p>
      <div className="heading">{heading}</div>
      {subHeading && <span className="sub-heading">{subHeading}</span>}
    </div>
  );
};

StepBlock.propTypes = {
  step: PropTypes.number,
  totalStep: PropTypes.number,
  heading: PropTypes.string,
  subHeading: PropTypes.string
};

export { StepBlock };
