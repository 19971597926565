import React from 'react';
import { Layout, Sidebar } from '../../components/common';
import { useTranslation } from 'react-i18next';
import useCustomerStore from '../../store/customerStore';

function ThankYou() {
  const { t } = useTranslation();
  const reset = useCustomerStore((state) => state.reset);
  reset();
  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <div className="thank-you">
          <p>{t('customer.thankYou')}</p>
        </div>
      </Layout>
    </div>
  );
}

export { ThankYou };
