import React from 'react';

const IconClose = (props) => (
  <svg
    viewBox="0 0 17 17"
    width={17}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="m14.88 15.728-6-6-6.007 6a1.13 1.13 0 0 1-1.6-1.591l6.008-6-6.007-6.015A1.125 1.125 0 1 1 2.865.53l6.008 6.008 6-6.008a1.126 1.126 0 0 1 1.591 1.592l-6 6.007 6 6a1.125 1.125 0 1 1-1.591 1.592l.007.007Z"
      fill="#4E665C"
    />
  </svg>
);

export default IconClose;
