import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Sidebar } from '../../components/common';
import '../../styles/forms/LoginForm.scss';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

function AdminLogin() {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      navigate('/admin/restaurants', { replace: true });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post('/admin/login', user);
    if (response.status === 201) {
      localStorage.setItem('token', response.data.access_token);
      navigate('/admin/restaurants', { replace: true });
    } else {
      toast.error(t('toast.loginError'));
    }
  };
  return (
    <div className="container">
      <Sidebar />
      <Layout>
        <form className="login-form" onSubmit={handleSubmit}>
          <label htmlFor="username">{t('admin.username')}</label>
          <input
            className="form-input"
            id="username"
            type="text"
            name="username"
            onChange={(e) => setUser({ ...user, username: e.target.value })}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
          <label htmlFor="password">{t('admin.password')}</label>
          <input
            className="form-input"
            id="password"
            type="password"
            name="password"
            autoComplete="on"
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            onInvalid={(e) => e.target.setCustomValidity(t('fieldFill'))}
            onInput={(e) => e.target.setCustomValidity('')}
            required
          />
          <div className="actions">
            <button type="submit" className="actions-next-link">
              {t('admin.login')}
            </button>
          </div>
        </form>
      </Layout>
    </div>
  );
}

export default AdminLogin;
