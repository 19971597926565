import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AdminUserRow({ restaurantName, name, email, loginTime, phoneNumber }) {
  const { t } = useTranslation();
  return (
    <div className="user-details-row">
      <div className="col-xl">
        <h2 className="heading">{t('admin.restaurantName')}</h2>
        <p>{restaurantName}</p>
      </div>
      <div className="col">
        <h2 className="heading">{t('customer.firstName')}</h2>
        <p>{name}</p>
      </div>
      <div className="col-l">
        <h2 className="heading">Email</h2>
        <p>{email}</p>
      </div>
      <div className="col">
        <h2 className="heading">Login Time</h2>
        <p>{loginTime}</p>
      </div>
      <div className="col">
        <h2 className="heading">Phone Number</h2>
        <p>{phoneNumber}</p>
      </div>
    </div>
  );
}

export default AdminUserRow;

AdminUserRow.propTypes = {
  restaurantName: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  loginTime: PropTypes.string,
  phoneNumber: PropTypes.string
};
