import React from 'react';
import '../../styles/common/layout.scss';
import PropTypes from 'prop-types';
import Language from '../../components/common/Language';
import { useLocation, useParams } from 'react-router-dom';
export const Layout = ({ admin, children }) => {
  const { pathname } = useLocation();
  const { slug } = useParams();
  return (
    <div className={`layout ${admin ? 'admin' : ''}`}>
      {slug || pathname === '/admin' || pathname === '/not-found' || pathname === '/' ? (
        <Language />
      ) : (
        <></>
      )}
      {children}
    </div>
  );
};

Layout.propTypes = {
  admin: PropTypes.bool,
  children: PropTypes.any
};
